import * as React from "react"
import Layout from "../components/Layout"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import Seo from "../components/SEO"
import SimpleBanner from "../components/SimpleBanner/SimpleBanner"
import { StaticImage } from "gatsby-plugin-image"
import BlogItem from "../components/Blog/BlogItem"
import BlogItems from "../components/Blog/BlogItems"

const Pagination = styled.aside`
  display: flex;
  margin: 0 auto;
  justify-content: center;

  .btn {
    margin-right: 20px;

    &:hover {
      cursor: pointer;
    }

    &.btn-active {
      color: var(--primary);

      &:after {
        display: none;
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }
`

const Blog = props => {
  return (
    <>
      <Seo title="Blogs" />
      <Layout>Blog</Layout>
    </>
  )
}

export default Blog
